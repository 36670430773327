<template>
  <div class="order">
    <div ref="search" class="search">
      <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
        <el-form :inline="true" :model="searchForm" label-width="80px">
          <el-form-item label="订单ID">
            <el-input v-model="searchForm.ordOiOrdercode" :size="$store.state.size" placeholder="订单ID" clearable
                      @keyup.enter.native="searchSubmit(true)"></el-input>
          </el-form-item>
          <el-form-item label="制造商">
            <el-select v-model="searchForm.ordOiManufacturerId" :size="$store.state.size" placeholder="制造商" clearable>
              <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName"
                         :value="e.sysCiId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button v-if="hasPermission('4-1-a')" type="primary" :size="$store.state.size" @click="searchSubmit"
                   icon="el-icon-search">查询
        </el-button>
        <el-button v-if="hasPermission('4-1-b')" type="default" plain :size="$store.state.size" @click="resetSubmit"
                   icon="el-icon-delete">清空
        </el-button>
      </el-card>
    </div>
    <el-table
        :data="tableData"
        :height="tableHeight"
        v-loading="tableLoading"
        border
        @sort-change="sortChange"
        :size="this.$common.tableLineHeight()"
        :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}"
        style="width: 100%; margin-top: 10px">
<!--      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>-->
      <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="ordOiOrdercode"
          align="center"
          sortable
          label="订单ID">
      </el-table-column>
      <el-table-column
          prop="ordOiManufacturer"
          align="center"
          sortable
          label="制造商"
          width="150">
      </el-table-column>
      <el-table-column
          prop="ordOiProduct"
          align="center"
          sortable
          label="品种">
      </el-table-column>
      <el-table-column
          prop="dateTime"
          align="center"
          sortable
          label="订单日期">
      </el-table-column>
      <el-table-column
          prop="ordOiRetailer"
          align="center"
          label="零售商">
      </el-table-column>
      <el-table-column
          prop="ordOiStatusShow"
          align="center"
          label="订单状态"
          width="130">
      </el-table-column>
      <el-table-column
          align="center"
          label="操作"
          width="120">
        <template slot-scope="scope">
          <el-button v-if="hasPermission('4-1-d')" type="text" size="small" @click="seeOpen(scope.row)"
                     icon="el-icon-document">查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :current-page="currentPage"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
    </pagination>

    <!--    查看模态框-->
    <assembly-dialog
        title="查看"
        :visible.sync="dialogVisibleSee"
        width="30%">
      <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
        <el-descriptions-item>
          <template slot="label">
            订单ID
          </template>
          {{ seeForm.ordOiOrdercode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            制造商
          </template>
          {{ seeForm.ordOiManufacturer }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            品种
          </template>
          {{ seeForm.ordOiProduct }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            订单日期
          </template>
          {{ seeForm.dateTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            零售商
          </template>
          {{ seeForm.ordOiRetailer }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            订单状态
          </template>
          {{ seeForm.ordOiStatusShow }}
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
      </span>
    </assembly-dialog>
  </div>
</template>

<script>

import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "@/components/assembly-dialog"

export default {
  name: "order",
  components: {
    pagination,
    AssemblyDialog
  },
  watch: {
    //监听头部展开收起变化
    '$store.state.isHeader'() {
      this.setTableHeight()
    },
    //监听快速导航展开收起变化
    '$store.state.fastNav'() {
      this.setTableHeight()
    },
  },
  data() {
    return {
      //加载
      tableLoading: false,
      //表格高度
      tableHeight: 0,
      //表格数据
      tableData: [],
      //当前页面
      currentPage: 1,
      //最大页数
      total: 0,
      //搜索表单
      searchForm: {
        page: 1,
        pageSize: this.$store.state.initPageSize,
        ordOiId: '',
        ordOiOrdercode:'',
        ordOiManufacturerId: '',
      },
      //查看模态框开关
      dialogVisibleSee: false,
      seeForm: {
        ordOiId: '',
        ordOiManufacturer: '',
        ordOiProduct: '',
        dateTime: '',
        ordOiRetailer: '',
        ordOiStatusShow: ''
      },
      //  制造商下拉列表
      manufacturerList: [],
    }
  },
  mounted() {
    //初始化计算表格区域高度
    this.setTableHeight();
    //初始化订单列表
    this.searchSubmit();
    //  初始化制造商列表
    this.manufacturer();
  },
  methods: {
    //判断权限是否存在
    hasPermission(item) {
      return this.$common.hasPermission(item);
    },
    /****初始化 start********************************************************************************/

    //制造商列表
    manufacturer() {
      this.$server.getList({sysCiType: 1}).then((res) => {
        if (res.ok) {
          console.log('制造商列表请求数据', JSON.stringify(res.data))
          this.manufacturerList = res.data
        }
      }).catch(e => {
        console.log(e)
      })
    },
    /****初始化 end********************************************************************************/

    /****表格高度计算 start********************************************************************************/
    //计算表格区域高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
        window.onresize = () => {
          return (() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
          })();
        };
      })
    },
    /****表格高度计算 end********************************************************************************/
    /****搜素区域 start********************************************************************************/
    //搜索表单提交
    searchSubmit(flag) {
      if (flag) {
        this.searchForm.page = 1
      }
      console.log('搜索表单提交参数', JSON.stringify(this.searchForm))
      this.tableLoading = true
      this.$server.getordorderlist(this.searchForm).then((res) => {
        if (this.searchForm.ordOiId != '' && this.searchForm.ordOiManufacturerId == '') {
          this.$message('请选择制造商');
        }else if(this.searchForm.ordOiId == '' && this.searchForm.ordOiManufacturerId != '') {
          this.$message('请输入订单编号');
        }
        this.tableLoading = false
        if (res.ok) {
          console.log('搜索表单获取参数', JSON.stringify(res.data))
          this.tableData = res.data.records
          this.total = res.data.total
        }
      }).catch(e => {
        this.tableLoading = false
        console.log(e)
      })
    },
    //搜索表单重置
    resetSubmit() {
      this.$common.clearFormValue(this.searchForm)
      this.searchSubmit()
      this.$common.clearFormValue(this.saveForm)
      this.searchForm.page = 1
    },
    /****搜素区域 end********************************************************************************/
    /****查看区域 start********************************************************************************/
    seeOpen(row) {
      this.seeForm = row
      console.log('查看区域==', JSON.stringify(row))

      this.dialogVisibleSee = true
    },
    seeClose() {
      this.dialogVisibleSee = false
    },
    /****查看区域 end********************************************************************************/
    /****删除区域 start********************************************************************************/
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.searchForm.pageSize = val
      this.searchSubmit()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.searchForm.page = val
      this.searchSubmit()
    },
    //排序
    sortChange(row) {
      console.log('排序排序排序', JSON.stringify(row))
      if (row.prop == 'ordOiManufacturer') {
        this.searchForm.orderByColumn = 'ordOiId'
      } else if (row.prop == 'ordOiProduct') {
        this.searchForm.orderByColumn = 'ordOiId'
      } else if (row.prop == 'ordOiRetailer') {
        this.searchForm.orderByColumn = 'ordOiId'
      } else {
        this.searchForm.orderByColumn = row.prop
      }
      if (row.order == 'ascending') {
        this.searchForm.order = 'asc'
      } else if (row.order == 'descending') {
        this.searchForm.order = 'desc'

      } else {
        this.searchForm.order = ''
      }
      this.searchForm.page = 1;
      this.searchSubmit()
    },
  },
}
</script>

<style lang="scss">
.order{
  .search {
    //搜索展开收起按钮位置设置
    .searchOpenBtn {
      float: right;
    }

    //表单每行底部外边距设置
    .el-form-item {
      margin-bottom: 10px;
    }

    .el-select {
      width: 200px;
    }

    .el-input {
      width: 200px;
    }

    //分割线外边距设置
    .el-divider--horizontal {
      margin: 0 0 10px 0;
    }
  }

  .el-dialog {
    .el-select {
      width: 100%;
    }
    .el-dialog__header{
      .fullscreen{
        float: right;
        color: #909399;
        cursor: pointer;
        font-size: small;
        font-weight: 400;
        line-height: 18px;
      }
      .fullscreen:hover{
        //color: ;
      }
    }
  }
}
</style>
